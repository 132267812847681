import(/* webpackChunkName: "entrypoint" */ './entry');

const head = document.getElementsByTagName('head')[0] as any;
const insertBefore = head.insertBefore;
head.insertBefore = function (newElement: any, referenceElement: any) {
    if (
        newElement.href && (
            newElement.href.includes('fonts.googleapis.com')
            || newElement.href.includes('fonts.gstatic.com')
        )
        || newElement.src && (
            newElement.src.includes('fonts.googleapis.com')
            || newElement.src.includes('fonts.gstatic.com')
        )
    ) {
        return;
    }
    insertBefore.call(head, newElement, referenceElement);
};

export { };
